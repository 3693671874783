import DataGrid, { IDataGridProps } from '@components/DataGrid'
import useApi from '@utils/hooks/useApi'
import { TUseForm, ValidationRule } from '@utils/hooks/useForm'
import { Skeleton } from '@mui/material'
import { getSandflies } from '@utils/api'
import { transformApiResponseToRows } from '@utils/tables'
import React, { ReactElement, useEffect, useState } from 'react'
import { columns } from '@pages/sandflies'
import { useLocation } from '@reach/router'
import { sandflyFilterPresets } from '@utils/sandfly'
import { Box, FormControl, FormHelperText, FormLabel } from '@mui/material'
import {
  GridFilterModel,
  GridLinkOperator,
  GridRowId
} from '@mui/x-data-grid-pro'

interface CustomizedState {
  sandfly_list?: GridRowId[]
}

export function FormNewScanSandflies({ form }: Props): ReactElement {
  const { loading, response, error } = useApi({ apiMethod: getSandflies })
  const location: any = useLocation()
  let sandfly_list = initialValues.sandfly_list

  if (location && location?.state?.sandfly_list) {
    sandfly_list = location.state.sandfly_list || []
  }

  const [filter, setFilter] = useState<GridFilterModel>(
    sandfly_list.length > 0
      ? {
          items: sandfly_list.map((id) => ({
            columnField: 'id',
            operatorValue: 'equals',
            value: `${id}`,
            id: `sandfly-${id}`
          })),
          linkOperator: 'or' as GridLinkOperator
        }
      : {
          items: [
            {
              columnField: 'active',
              operatorValue: 'is',
              value: 'true',
              id: '1'
            }
          ],
          linkOperator: 'and' as GridLinkOperator
        }
  )

  const dataGridConfig: IDataGridProps['config'] = {
    loading,
    elevation: 0,
    error: error || null,
    columns,
    variant: form.errors.sandfly_list ? 'error' : 'default',
    filterModel: filter,
    setFilter,
    disableColumnReorder: true,
    disableColumnSelector: true,
    rows: transformApiResponseToRows({
      response
    }),
    onSelectionModelChange: (selectionModel) => {
      form.setFieldValue('sandfly_list', selectionModel)
    },
    selectionModel: form.values.sandfly_list,
    height: 527,
    presets: sandflyFilterPresets
  }

  useEffect(() => {
    if (location && location.state) {
      const { sandfly_list = [] } = location.state as CustomizedState
      form.setFieldValue('sandfly_list', sandfly_list)
    }
  }, [location.state])

  return (
    <>
      {!response && (
        <Skeleton
          variant="rectangular"
          width={'100%'}
          height={dataGridConfig.height}
        />
      )}

      {response && (
        <FormControl error={form.errors.sandfly_list} fullWidth>
          <FormLabel>Available Sandflies</FormLabel>
          <Box my={1}>
            <DataGrid config={dataGridConfig} />
          </Box>
          <FormHelperText>Select at least one sandfly.</FormHelperText>
        </FormControl>
      )}

      {error &&
        {
          /* TODO: Handle Errors */
        }}
    </>
  )
}

export type TFormNewScanSandflies = {
  sandfly_list: GridRowId[]
}

export const initialValues: TFormNewScanSandflies = {
  sandfly_list: []
}

export const initialValidation: ValidationRule<TFormNewScanSandflies> = {
  sandfly_list: (value) => value.length > 0
}

interface Props {
  form: TUseForm<TFormNewScanSandflies>
}
