import {
  FormNewScanSandflies,
  initialValidation as sandfliesValidation,
  initialValues as sandfliesValues,
  TFormNewScanSandflies
} from './FormNewScanSandflies'
import {
  FormNewScanHosts,
  initialValidation as hostsValidation,
  initialValues as hostsValues,
  TFormNewScanHosts
} from './FormNewScanHosts'
import { ValidationRule } from '@utils/hooks/useForm'

export type TFormNewScan = TFormNewScanHosts & TFormNewScanSandflies

export const initialValues: TFormNewScan = {
  ...hostsValues,
  ...sandfliesValues
}

export const initialValidation: ValidationRule<TFormNewScan> = {
  ...hostsValidation,
  ...sandfliesValidation
}

export { FormNewScanHosts, FormNewScanSandflies }
