import DataGrid, { IDataGridProps } from '@components/DataGrid'
import Select from '@components/Select'
import useApi from '@utils/hooks/useApi'
import useForm, { TUseForm, ValidationRule } from '@utils/hooks/useForm'
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack
} from '@mui/material'
import { Skeleton } from '@mui/material'
import { getHosts } from '@utils/api'
import {
  chipRenderer,
  hostActiveRenderer,
  tooltipRenderer,
  transformApiResponseToRows
} from '@utils/tables'
import React, { ReactElement, useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { hostPresets } from '@utils/hosts'
import {
  GridFilterModel,
  GridLinkOperator,
  GridRowId
} from '@mui/x-data-grid-pro'

export type TFormNewScanHosts = {
  priority: number
  host_ids: GridRowId[]
}

export const initialValues: TFormNewScanHosts = {
  priority: 0,
  host_ids: []
}

export const initialValidation: ValidationRule<TFormNewScanHosts> = {
  host_ids: (value) => value.length > 0
}

interface Props {
  form: TUseForm<TFormNewScanHosts>
}

interface CustomizedState {
  host_ids?: GridRowId[]
}

export function FormNewScanHosts({ form }: Props): ReactElement {
  const { loading, response, error } = useApi({ apiMethod: getHosts })
  const location = useLocation() as any

  let host_ids: GridRowId[] = initialValues.host_ids

  if (location && location?.state?.host_ids) {
    host_ids = location.state.host_ids || []
  }

  const [filter, setFilter] = useState<GridFilterModel>(
    host_ids.length
      ? {
          items: host_ids.map((id) => ({
            columnField: 'id',
            operatorValue: 'equals',
            value: `${id}`,
            id: `host-${id}`
          })),
          linkOperator: 'or' as GridLinkOperator
        }
      : {
          items: [
            {
              columnField: 'active',
              operatorValue: 'is',
              value: 'true',
              id: '1'
            }
          ],
          linkOperator: 'and' as GridLinkOperator
        }
  )

  useEffect(() => {
    if (location && location.state) {
      const { host_ids = [] } = location.state as CustomizedState
      form.setFieldValue('host_ids', host_ids)
    }
  }, [location.state])

  const dataGridConfig: IDataGridProps['config'] = {
    loading,
    elevation: 0,
    error: error || null,
    filterModel: filter,
    setFilter,
    variant: form.errors.host_ids ? 'error' : 'default',
    disableColumnReorder: true,
    disableColumnSelector: true,
    columns: [
      {
        field: 'id',
        headerName: 'Host Id',
        width: 200,
        renderCell: tooltipRenderer,
        hide: true
      },
      {
        field: 'hostname',
        headerName: 'Target Address',
        width: 150,
        type: 'enhancedString'
      },
      {
        field: 'os_info_node',
        headerName: 'Hostname',
        width: 150,
        type: 'enhancedString',
        renderCell: tooltipRenderer
      },

      {
        field: 'active',
        headerName: 'Active',
        type: 'boolean',
        width: 110,
        renderCell: hostActiveRenderer
      },
      {
        field: 'authentication_status',
        headerName: 'Auth Status',
        width: 200,
        type: 'enhancedString'
      },
      {
        field: 'credentials_id',
        headerName: 'Credential',
        width: 180,
        type: 'enhancedString'
      },
      {
        field: 'tags',
        headerName: 'Tags',
        flex: 1,
        renderCell: chipRenderer,
        type: 'enhancedString'
      }
    ],
    rows: transformApiResponseToRows({
      response
    }),
    onSelectionModelChange: (selectionModel) => {
      form.setFieldValue('host_ids', selectionModel)
    },
    selectionModel: form.values.host_ids,
    height: 527,
    presets: hostPresets
  }

  return (
    <Stack spacing={3}>
      {!response && (
        <Skeleton
          variant="rectangular"
          width={'100%'}
          height={(dataGridConfig.height as number) + 54}
        />
      )}

      {response && (
        <FormControl error={form.errors.host_ids} fullWidth>
          <FormLabel>Available Hosts</FormLabel>
          <Box my={1}>
            <DataGrid config={dataGridConfig} />
          </Box>
          <FormHelperText>Select at least one host.</FormHelperText>
        </FormControl>
      )}

      <Select
        id="priority"
        label="Priority (Nice Level)"
        isNumber={true}
        data-cy="formNewScanHostsPriority"
        options={[
          {
            value: 10,
            label: 'Low'
          },
          {
            value: 0,
            label: 'Normal'
          },
          {
            value: -10,
            label: 'High'
          }
        ]}
        inputProps={{
          'data-cy': 'formNewScanHostsPriority'
        }}
        form={form as any}
      />
    </Stack>
  )
}

export function validateFormAddHost(form: ReturnType<typeof useForm>) {
  return form.validateFields([
    'host_select_type',
    'ip_network',
    'ssh_port',
    'tags'
  ])
}
