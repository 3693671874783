import React from 'react'
import useForm, { TUseForm } from '@hooks/useForm'
import { scanHosts } from '@utils/api'
import SteppedFormPage from '@templates/Generic/SteppedForm'
import {
  FormNewScanHosts,
  FormNewScanSandflies,
  initialValues,
  initialValidation,
  TFormNewScan
} from '@components/Form/FormNewScan'
import { PageProps } from 'gatsby'
import { GridRowId } from '@mui/x-data-grid-pro'
import useApi from '@hooks/useApi'

function getStepContent(step: number, form: any) {
  switch (step) {
    case 0:
      return <FormNewScanHosts form={form} />
    case 1:
      return <FormNewScanSandflies form={form} />
    default:
      return <>{'Unknown step'}</>
  }
}

function isStepValid(step: number, form: any) {
  switch (step) {
    case 0:
      return form.validateFields(['host_ids'])
    case 1:
      return form.validateFields(['sandfly_list'])
    default:
      return true
  }
}

const NewScanPage = ({
  location
}: PageProps<
  object,
  object,
  {
    sandfliesFirst: boolean
    host_ids?: GridRowId[]
    sandfly_list?: GridRowId[]
  }
>) => {
  const api = useApi({ apiMethod: scanHosts, requestOnMount: false })
  const state = location?.state || {}
  const form = useForm<TFormNewScan>({
    initialValues: {
      ...initialValues,
      ...state
    },
    initialValidationRules: initialValidation
  })

  // TODO: Add reverse support.
  const formOptions = {
    steps: ['Select Host(s)', 'Select Sandflies'],
    api: api,
    isStepValid,
    getStepContent,
    error: api.error
  }

  return (
    <SteppedFormPage
      title="New Scan"
      maxWidth={false}
      breadcrumbs={[
        {
          title: 'Scanning'
        }
      ]}
      form={form as TUseForm<any>}
      {...formOptions}
    />
  )
}

export default NewScanPage
